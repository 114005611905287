'use client';

import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebaseConfig from '@/lib/firebase/utils/firebaseConfig';
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

export const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

/* 
// Initialize App Check
if (typeof window !== 'undefined') {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!),
    isTokenAutoRefreshEnabled: true,
  });
}
*/

export const auth = getAuth(app);
