import { CUSTOMER_ROLE } from '@/constants/roles';

export const COLLECTION_OTPS = 'otps';
export const COLLECTION_CONVERSATIONS = 'conversations';
export const COLLECTION_MESSAGES = 'messages';

export const COOKIE_NAME = 'token';

export const INITIAL_USER_DATA = {
  role: CUSTOMER_ROLE,
};
